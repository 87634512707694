import React, { useEffect, useRef, useState } from "react";
import CustomProgressBar from "../../Components/Elements/progress_bar";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BrowserView, MobileView } from "react-device-detect";
import { TailSpin } from "react-loader-spinner";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SweetAlert from 'react-bootstrap-sweetalert';

let apiServices = new ApiService();
function ClientDetails() {
  const navigate = useNavigate();
  const [registerData, setRegisterData] = useState("")
  const [userData, setUserData] = useState("")
  const [bankDetail, setBankDetail] = useState([])
  const [userId, setUserId] = useState(0);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [countermob, setCountermob] = React.useState(0);
  const [seconds, setseconds] = useState(0);
  const [minutes, setminutes] = useState(10);
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [remark, setRemark] = useState("")
  const [showmodal, setShowmodal] = useState(false);
  

  const handleClose = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);
  const [otpData, setOtpData] = useState(
    {
      mobile_no: "",
      mobile_otp: "",
    },
  );
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      setSpinnerLoading(true)
      apiServices
        .bsegetuserdataPostRequest({})
        .then((result) => {
          if (result.data.status === 'success') {
           setSpinnerLoading(false)
            setUserData(result.data.data)
            setRegisterData(result.data.bse_register_data)
         
              setBankDetail(result.data.bse_bank_data)
            
          } else if (result.data.status === "error" && result.data.message === "Session expired") {
            navigate("/");
            localStorage.removeItem('AUTH_TOKEN');
            setSpinnerLoading(false)
          }
        })
        .catch((error) => {
          setSpinnerLoading(false)
        });
      didMountRef.current = true;
    }
  }, []);
  const handleBackClick = () => {
    navigate("/bseonboarding/step2");
  }
  const handleInput = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setOtpData({ ...otpData, [key]: value });
  };
  const sendbseonboardotp = (e) => {
    e.preventDefault();
    const dataString = {
      mobile_otp: otpData.mobile_otp
    }
    apiServices.bseendonboardingotpUrlPostRequest(dataString).then(result => {
      if (result.data.status == 'success') {
        // localStorage.setItem('AUTH_TOKEN', result.data.auth_token);

        setCountermob(30)
        setminutes(10);
        setseconds(0);
        return false;
      }
      else {
        toast(result.data.message);
      }
      setShowLoading(false);
    })
      .catch(error => {
      })
  };
 
  const verifybseonboardotp = () => {
    if (otpData?.mobile_otp == "" || otpData?.mobile_otp === null) {
      toast.error("Please enter the otp send on registered mobile no");
      return;
    }
  
    const dataString = {
      otp: otpData.mobile_otp
    }
    apiServices.verifybseonboardotpPostRequest(dataString).then(result => {
      if (result.data.status == 'success') {
        toast.success(result.data.data);
        setSpinnerLoading(true)
        apiServices.uccRegistrationpostrequest({}).then(result => {
          // if (result.data.status == 'success') {

          // }
          setSpinnerLoading(false)
          setShow(true)
          setRemark(result.data.message)

        })
      }
      else if (result.data.message == "Session expired") {
        localStorage.removeItem('AUTH_TOKEN');
        navigate("/");
        setSpinnerLoading(false)
      }
    })
  }
 
  return (
    <>
  <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
      <BrowserView>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
        </div>
        <section className="sec-pad-lg bg-light">
          <div className="container">
            <div className=" d-flex justify-content-center">
              <a href="#" ><img className="wd-150 mb-4" src="/img/logo.png"></img></a>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="panel">
                  <div className="panel-header text-center"><h5 className="mb-0">Client Registration Details</h5></div>
                  <div className="panel-body" style={{ padding: '0px' }}>
                    <table className="clienttable">
                      <tr>
                        <td className="name">Client Code</td>
                        <td>{userData.gs_bse_regid ? userData.gs_bse_regid : "-"}</td>
                        <td className="name">Client Name</td>
                        <td>{userData.gs_fname ? userData.gs_fname : "-"}</td>
                      </tr>
                      <tr>
                        <td className="name">DOB</td>
                        <td>{userData.gs_dob ? userData.gs_dob : "-"}</td>
                        <td className="name">PAN No.</td>
                        <td>{userData.gs_pan_no ? userData.gs_pan_no : "-"}</td>
                      </tr>
                      <tr>
                        <td className="name">Mobile Number</td>
                        <td>{userData.gs_mobile ? userData.gs_mobile : "-"}</td>
                        <td className="name">Email</td>
                        <td>{userData.gs_email ? userData.gs_email : "-"}</td>
                      </tr>
                      <tr>
                        <td className="name">Tax Status</td>
                        <td>{registerData.bur_tax_status ? registerData.bur_tax_status : "-"}</td>
                        <td className="name">Holding Nature</td>
                        <td>SINGLE</td>
                      </tr>
                      <tr>
                        <td className="name">Nominee</td>
                        <td>{registerData.bur_nominee_opt ? registerData.bur_nominee_opt : "-"}</td>
                      </tr>
                      <tr>
                        <td colSpan="4"><strong>Bank Details</strong></td>
                      </tr>
                      {bankDetail.map((value)=>(
                        <>
                      <tr>
                        <td className="name">Acc Type</td>
                        <td>{value.ubd_acc_type ? value.ubd_acc_type : "-"}</td>
                        <td className="name">Acc No.</td>
                        <td>{value.ubd_acc_no ? value.ubd_acc_no : "-"}</td>
                      </tr>
                      <tr>
                        <td className="name">Bank Name</td>
                        <td>{value.ubd_bank_name ? value.ubd_bank_name : "-"}</td>
                        <td className="name">IFSC</td>
                        <td>{value.ubd_ifsc ? value.ubd_ifsc : "-"}</td>
                      </tr>
                      <tr>
                        <td className="name">MICR No</td>
                        <td>{value.ubd_micr ? value.ubd_micr : "-"}</td>
                        <td className="name">Bank Branch</td>
                        <td>{value.ubd_bank_branch ? value.ubd_bank_branch : "-"}</td>
                      </tr>
                      </>
                      ))}
                    </table>
                  </div>
                  <div className="panel-footer dflex">
                    <button
                      className="btn btn-primary btn-lg btn-full me-2 "
                      onClick={handleBackClick}
                    >
                      Edit
                    </button>
                    {showOTPInput ?
                      <button
                        className="btn btn-primary btn-lg btn-full "
                        onClick={verifybseonboardotp}
                      >
                        Submit
                      </button>
                      : <button
                        className="btn btn-primary btn-lg btn-full "
                        onClick={(e) => {
                          e.preventDefault();
                          sendbseonboardotp(e);
                          handleShow()
                        }}
                      >
                        Accept
                      </button>}
                  </div>


                </div>
              </div>
            </div>
          </div>
        </section>
      </BrowserView>
      <MobileView>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        <div className=" d-flex justify-content-center">
          <a href="#" ><img className="wd-150 mb-4 mt-4" src="/img/logo.png"></img></a>
        </div>
        <table class=" clienttable">
        
          <tbody>
            <tr >
              <td className="name">Client Code</td>
              <td>{userData.gs_bse_regid ? userData.gs_bse_regid : "-"}</td>
            </tr>
            <tr>
              <td className="name">Client Name</td>
              <td>{userData.gs_fname ? userData.gs_fname : "-"}</td>
            </tr>
            <tr>
              <td className="name">DOB</td>
              <td>{userData.gs_dob ? userData.gs_dob : "-"}</td>
            </tr>
            <tr>
              <td className="name">Mobile Number</td>
              <td>{userData.gs_mobile ? userData.gs_mobile : "-"}</td>
            </tr>
            <tr>
              <td className="name">PAN No.</td>
              <td>{userData.gs_pan_no ? userData.gs_pan_no : "-"}</td>
            </tr>
            <tr>
              <td className="name">Tax Status</td>
              <td>{registerData.bur_tax_status ? registerData.bur_tax_status : "-"}</td>
            </tr>
            <tr>
              <td className="name">Holding Nature</td>
              <td>{registerData.bur_holding_nature ? registerData.bur_holding_nature : "-"}</td>
            </tr>
            <tr>
              <td className="name">Email</td>
              <td>{userData.gs_email ? userData.gs_email : "-"}</td>
            </tr>
            <tr>
              <td className="name">Nominee OPT</td>
              <td>{registerData.bur_nominee_opt ? registerData.bur_nominee_opt : "-"}</td>
            </tr>
            <tr>
              <td colSpan="4"><strong>Bank Details</strong></td>
            </tr>
            <tr>

              <td className="name">Acc Type</td>
              <td>{bankDetail.ubd_acc_type ? bankDetail.ubd_acc_type : "-"}</td>

            </tr>
            <tr>
              <td className="name">Acc No.</td>
              <td>{bankDetail.ubd_acc_no ? bankDetail.ubd_acc_no : "-"}</td>
            </tr>
            <tr>
              <td className="name">Bank Name</td>
              <td>{bankDetail.ubd_bank_name ? bankDetail.ubd_bank_name : "-"}</td>
            </tr>
            <tr>
              <td className="name">IFSC</td>
              <td>{bankDetail.ubd_ifsc ? bankDetail.ubd_ifsc : "-"}</td>
            </tr>
            <tr>
              <td className="name">MICR No</td>
              <td>{bankDetail.ubd_micr ? bankDetail.ubd_micr : "-"}</td>
            </tr>
            <tr>
              <td className="name">Bank Branch</td>
              <td>{bankDetail.ubd_bank_branch ? bankDetail.ubd_bank_branch : "-"}</td>
            </tr>
            <tr>
              <td>Tax Status</td>
              <td>NRi-Minor</td>
            </tr>
          </tbody>
          <div className="footer-button">
            <div className="d-flex mt-5">
              <button
                className="btn btn-primary btn-lg btn-full me-2 "
                onClick={handleBackClick}
              >
                Edit
              </button>
              <button
                className="btn btn-primary btn-lg btn-full "
                onClick={(e) => {
                  e.preventDefault();
                  sendbseonboardotp(e);
                  handleShow()
                }}
              >
                Accept
              </button>
            </div>
          </div>
        </table>
      </MobileView>
      <Modal show={showmodal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body> <div className="from-group mb-3">
          <label>Enter OTP</label>
          <input
            type="number"
            name="mobile_otp"
            onChange={handleInput}
          ></input>
          {/* <p>We will send you OTP on your Mobile.</p> */}
        </div></Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button variant="primary" onClick={() => { verifybseonboardotp() }}>
            Verify Otp
          </Button>
        </Modal.Footer>
      </Modal>
      <SweetAlert warning confirmBtnCssClass='alertpop' confirmBtnText='Ok' title={remark} show={show}>
      </SweetAlert>
    </>
  );
}
export default ClientDetails;
