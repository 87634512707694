import React, { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import SignatureCanvas from 'react-signature-canvas';
import { ToastContainer, toast } from "react-toastify";
import html2canvas from 'html2canvas';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from "react-router-dom";
import { Hourglass } from "react-loader-spinner";
import { TailSpin } from "react-loader-spinner";
import { ApiService } from "../../Components/Services/apiServices";
let apiServices = new ApiService();
function BseAufForm() {
  const didMountRef = useRef(true);
  const [aufData, setAufData] = useState({})
  const [bankData, setBankData] = useState([])
  const [primarybank, setprimarybank] = useState("")
  const [nomineeData, setNomineeData] = useState([])
  const [registerData, setRegisterData] = useState({})
  const [counter, setCounter] = React.useState(0);
  const [countermob, setCountermob] = React.useState(0);
  const [seconds, setseconds] = useState(0);
  const [minutes, setminutes] = useState(10);
  const [loaderName, setLoaderName] = useState("")
  const [submitLoader, setSubmitLoader] = useState(false)
  const [showmodal, setShowmodal] = useState(false);
  const [UserUCC, setUserUCC] = useState('');
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const [otpData, setOtpData] = useState(
    {
      mobile_no: "",
      mobile_otp: "",
    },
  );
  const [show , setShow] = useState(false)
  const inputRef = useRef(null);
  const handlemodalclose = () => setShowmodal(false)
  const contentRef = useRef(null);

  const navigate = useNavigate()
  useEffect(() => {
    if (didMountRef.current) {
      setSpinnerLoading(true)
      apiServices.getuserdatawithoutraworignalPostRequest({}).then((result) => {
        if (result.data.status === 'success') {
          setAufData(result.data.data)
          setBankData(result.data.bse_bank_data)
          setprimarybank(result.data.userPrimaryBankData?.ubd_id)
          setNomineeData(result.data.bse_nominee_data)
          setRegisterData(result.data.bse_register_data)
          setSpinnerLoading(false)
        } else if (result.data.status === "error" && result.data.message === "Session expired") {
          navigate("/");
          localStorage.removeItem('AUTH_TOKEN');
          setSpinnerLoading(false)
        }
      })
        .catch((error) => {
          setSpinnerLoading(false)
        });
      didMountRef.current = true;
    }
  }, []);


  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  React.useEffect(() => {
    countermob > 0 && setTimeout(() => setCountermob(countermob - 1), 1000);
  }, [countermob]);

  React.useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setseconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
        } else {
          setseconds(59);
          setminutes(minutes - 1);
        }
      }
    }, 1000);
  });
  const resendOtpbr = () => {
    const dataString = {
      mobile_otp: otpData.mobile_otp
    }
    apiServices.bseendonboardingotpUrlPostRequest(dataString).then(res => {
      if (res.data.status == 'success') {
        setCounter(30);
        setCountermob(30)
        setminutes(10);
        setseconds(0);
        return false;
      }
      else {
        toast(res.data.message);
        return false;
      }
    })
  }
  const handleInput = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setOtpData({ ...otpData, [key]: value });
  };
  const handleBackClick = () => {
    navigate("/completeprofile");
  }
  const handleShow = () => setShowmodal(true);
  const verifybseonboardotp = () => {
    if (otpData?.mobile_otp == "" || otpData?.mobile_otp === null) {
      toast.error("Please Enter the OTP ");
      return;
    }
    // setSubmitLoader(true);
    const dataString = {
      otp: otpData.mobile_otp
    }
    if(aufData.gs_onboard_fullreg_status == 1){
      apiServices.verifybseonboardotpPostRequest(dataString).then(result => {
        if (result.data.status == 'success') {
              setLoaderName("Uploading Fatca details")
              apiServices.getFatcaapirequest({}).then((response) => {
                if (response?.data?.data?.Status == 100 && response?.data?.status == "success") {
                  setLoaderName("Uploading AUF form")
                // toast.success("UCC created successfully please authenticate it from your mail ID")
                html2canvas(contentRef.current).then(function (canvas) {
                  const dataURL = canvas.toDataURL('image/png');
                  const dataString = {
                    screenshot: dataURL
                  }
                  apiServices.bseaufscreenshotuploadPostRequest(dataString).then((res) => {
                    setLoaderName("")
                    if (res?.data?.status == "success") {
                      setShow(true);       
                      setUserUCC(res?.data?.ucc)
                      setSubmitLoader(false)
                    }
                    else {
                      toast.error(res?.data?.message)
                      handlemodalclose();
                      setSubmitLoader(false)
                    }
                  })
            
                });
                }
                else {
                  toast.error(response?.data?.data?.ResponseString)
                  setSubmitLoader(false)
                }
              })
        }
        else if (result.data.status == "error") {
          toast.error(result.data.message)
          setSubmitLoader(false)
        }
        else if (result.data.message == "Session expired") {
          navigate("/");
        }
      })
    }
    else{
      apiServices.verifybseonboardotpPostRequest(dataString).then(result => {
        if (result.data.status == 'success') {
          setLoaderName("Uploading Client registration Details")
          // setShowmodal(false)
          apiServices.uccRegistrationpostrequest({}).then(result => {
            if (result.data.status == "success") {
              setLoaderName("Uploading Fatca details")
              apiServices.getFatcaapirequest({}).then((response) => {
                if (response?.data?.data?.Status == 100 && response?.data?.status == "success") {
                  setLoaderName("Uploading AUF form")
                // toast.success("UCC created successfully please authenticate it from your mail ID")
                html2canvas(contentRef.current).then(function (canvas) {
                  const dataURL = canvas.toDataURL('image/png');
                  const dataString = {
                    screenshot: dataURL
                  }
                  apiServices.bseaufscreenshotuploadPostRequest(dataString).then((res) => {
                    setLoaderName("")
                    if (res?.data?.status == "success") {
                      setShow(true);       
                      setUserUCC(res?.data?.ucc)
                      setSubmitLoader(false)
                    }
                    else {
                      toast.error(res?.data?.message)
                      handlemodalclose();
                      setSubmitLoader(false)
                    }
                  })
            
                });
                }
                else {
                  toast.error(response?.data?.data?.ResponseString)
                  setSubmitLoader(false)
                }
              })
            }
            else if (result.data.status == "error") {
              toast.error(result.data.message)
              setSubmitLoader(false)
              return
            }
          })
        }
        else if (result.data.status == "error") {
          toast.error(result.data.message)
          setSubmitLoader(false)
        }
        else if (result.data.message == "Session expired") {
          navigate("/");
        }
      })
    }
    
  }
  const sendbseonboardotp = (e) => {
    setSubmitLoader(true)
    e.preventDefault();
    const dataString = {
      mobile_otp: otpData.mobile_otp
    }
    apiServices.bseendonboardingotpUrlPostRequest(dataString).then(result => {
      if (result.data.status == 'success') {
        setCounter(30);
        setCountermob(30)
        setSubmitLoader(false)
        handleShow()
        return false;
      }
      else {
        toast(result.data.message);
        setSubmitLoader(false)
      }
    })
      .catch(error => {
        setSubmitLoader(false)
      })
  };

  const uploadfile=()=>{
    setSubmitLoader(true)
    setLoaderName("Uploading AUF form")
   
  }

  const confirmContinue =()=>{
    // navigate(`/bankmandate/${primarybank}`)
    navigate(`/dashboard`)
  }
  return (
    <>
     <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
      <div className={submitLoader == true ? 'parentDisable' : ''} width="100%">
        <div className='overlay-box'>
          <Hourglass
            visible={submitLoader}
            ariaLabel="Loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={['#304AA7', '#304AA7']}
          />
          <p>{loaderName}</p>
        </div>
      </div>
      <BrowserView>
        <div className="auth-page-wrapper pt-50">
          <div className="auth-page-content">
            <div className="container">
              <div className="row justify-content-center mt-3">
                <div className="col-lg-8">
                  <div className="crds">
                    <div className="crds-body p-50">
                      <div className="text-center mb-30">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                      </div>
                    
                      <table className="table table-bordered bsetable" ref={contentRef}>
                        <tbody>
                          <tr>
                            <td><span className="tx-gray fw-bold">Broker/Agent Code ARN</span> : 249633</td>
                            <td><span className="tx-gray fw-bold">Sub Broker</span> : -</td>
                            <td colSpan="3"><span className="tx-gray fw-bold">EUIN</span> : {aufData.gs_txn_euin}</td>
                          </tr>
                          <tr>
                            <td colSpan="6"><b>Unit Folder Information</b></td>
                          </tr>
                          <tr>
                            <td><b>Name of the First Applicant : </b></td>
                            <td colSpan="5">{aufData.gs_fname} {aufData.gs_lname}</td>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">PAN Number</span> : {aufData.gs_pan_no}</td>
                            <td><span className="tx-gray fw-bold">KYC</span> : {registerData.bur_kyc_type}</td>
                            <td colSpan="4"><span className="tx-gray fw-bold">Date of Birth</span> : {aufData.gs_dob}</td>
                          </tr>
                          <tr>
                            <td colSpan="2"><span className="tx-gray fw-bold">Father Name</span> :  -</td>
                            <td colSpan="4"><span className="tx-gray fw-bold">Mother Name</span> :  -</td>
                          </tr>
                          <tr>
                            <td colSpan="2"><span className="tx-gray fw-bold">Name of Guardian</span> : -</td>
                            <td colSpan="4"><span className="tx-gray fw-bold">PAN</span> : -</td>
                          </tr>
                          <tr>
                            <td colSpan="6"><b>Contact Address : </b></td>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">City</span> : {aufData.gs_city}</td>
                            <td colSpan="1"><span className="tx-gray fw-bold">Pincode</span> : {aufData.gs_pin}</td>
                            <td colSpan="2"><span className="tx-gray fw-bold">State</span> : {aufData.gs_state}</td>
                            <td colSpan="2"><span className="tx-gray fw-bold">Country</span> : {aufData.gs_country}</td>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">Tel(off)</span> : -</td>
                            <td><span className="tx-gray fw-bold">Tel(Res)</span> : -</td>
                            <td colSpan="4"><span className="tx-gray fw-bold">Email</span> : {aufData.gs_email}</td>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">Fax(off)</span> : -</td>
                            <td><span className="tx-gray fw-bold">Fax(Res)</span> : -</td>
                            <td colSpan="4"><span className="tx-gray fw-bold">Mobile</span> : {aufData.gs_mobile}</td>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">Income Tax Slab/NetWorth</span> : {registerData.bur_income_source}</td>
                            <td colSpan="6"><span className="tx-gray fw-bold">Occupation Details</span> : {registerData.bur_occ_code}</td>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">Place of Birth</span> : {aufData.gs_palce_of_birth}</td>
                            <td colSpan="6"><span className="tx-gray fw-bold">Country of Tax Residence</span> : {registerData.bur_tax_resistance_country}</td>
                          </tr>
                          <tr>
                            <th colSpan="6">Tax Id No : -</th>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">Politically exposed person/Related to Politically exposed person etc.</span></td>
                            <td colSpan="6">{registerData?.bur_politically_expose == "Y" ? "Yes" : registerData?.bur_politically_expose == "N" ? "No" : "Relative"}</td>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">Mode of Holding</span> : {registerData.bur_tax_status}</td>
                            <td colSpan="5"><span className="tx-gray fw-bold">Occupation</span> : {registerData.bur_occ_code}</td>
                          </tr>
                          <tr>
                            <th>Name of Second Applicant : </th>
                            <td colSpan="6"></td>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">PAN Number</span> : </td>
                            <td><span className="tx-gray fw-bold">KYC</span> :</td>
                            <td colSpan="4"><span className="tx-gray fw-bold">Date of Birth</span> :</td>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">Place of Birth</span> :</td>
                            <td colSpan="5"><span className="tx-gray fw-bold">Country of Tax Residence</span> :</td>
                          </tr>
                          <tr>
                            <th colSpan="6">Tax Id No : </th>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">Politically exposed person/Related to Politically exposed person etc.</span> :</td>
                            <td>Yes :</td>
                            <td colSpan="4">No :</td>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">Mode of Holding</span> :</td>
                            <td colSpan="5"><span className="tx-gray fw-bold">Occupation</span> :</td>
                          </tr>
                          <tr>
                            <th colSpan="6">Name of Third Applicant :</th>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">PAN Number</span> :</td>
                            <td><span className="tx-gray fw-bold">KYC</span> :</td>
                            <td colSpan="4"><span className="tx-gray fw-bold">Date of Birth</span> :</td>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">Place of Birth</span> : </td>
                            <td colSpan="5"><span className="tx-gray fw-bold">Country of Tax Residence</span> :-</td>
                          </tr>
                          <tr>
                            <th colSpan="6">Tax Id No :</th>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">Politically exposed person/Related to Politically exposed person etc.</span>:</td>
                            <td colSpan={3}>Yes</td>
                            <td>No</td>
                         </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">Other Details of sole/1st Applicant</span> :</td>
                            <td colSpan="5"><span className="tx-gray fw-bold">Overseas Address(In case of NRI Investor)</span> :</td>
                          </tr>
                          <tr>
                            <td><span className="tx-gray fw-bold">City</span> :</td>
                            <td><span className="tx-gray fw-bold">Pincode</span> :</td>
                            <td colSpan="4"><span className="tx-gray fw-bold">Country</span> :</td>
                          </tr>
                          {bankData.map((value) => (
                            <>
                              <tr>
                                <th colSpan="6">Bank Mandate 1 Details:</th>
                              </tr>
                              <tr>
                                <td><span className="tx-gray fw-bold">Name of Bank</span> : {value.bank.bse_bank_name}</td>
                                <td colSpan="5"><span className="tx-gray fw-bold">Branch</span> : {value.ubd_bank_branch}</td>
                              </tr>
                              <tr>
                                <td><span className="tx-gray fw-bold">A/C Number</span> : {value.ubd_acc_no}</td>
                                <td><span className="tx-gray fw-bold">A/C Type</span> :{value.ubd_acc_type}</td>
                                <td colSpan="5"><span className="tx-gray fw-bold">IFSC Code</span> : {value.ubd_ifsc}</td>
                              </tr>
                              <tr>
                                <td><span className="tx-gray fw-bold">City</span> : {aufData.gs_city}</td>
                                <td><span className="tx-gray fw-bold">Pincode</span> : {aufData.gs_pin}</td>
                                <td><span className="tx-gray fw-bold">State</span> : {aufData.gs_state}</td>
                                <td colSpan="4"><span className="tx-gray fw-bold">Country</span> : {aufData.gs_country}</td>
                              </tr>
                            </>))}
                          <tr>
                            <th colSpan="6">Nomination Details:</th>
                          </tr>
                          {nomineeData.map((value) => (
                            <>
                              <tr>
                                <td><span className="tx-gray fw-bold">Nominee Name</span> : {value.und_nominee_name}</td>
                                <td colSpan="5"><span className="tx-gray fw-bold">Relationship</span> : {value.und_nominee_relationship_name}</td>
                              </tr>
                              <tr>
                                <td><span className="tx-gray fw-bold">Guardian Name(If Nominee is Minor)</span> : {value.und_nominee_guardian}</td>
                                <td><span className="tx-gray fw-bold">A/C Type</span> : </td>
                                <td colSpan="4"><span className="tx-gray fw-bold">IFSC Code</span> :</td>
                              </tr>
                              <tr>
                                <th colSpan="6"><span className="tx-gray fw-bold">Nominee Address</span> :</th>
                              </tr>
                              <tr>
                                <td><span className="tx-gray fw-bold">City</span> : </td>
                                <td><span className="tx-gray fw-bold">Pincode</span> : </td>
                                <td colSpan="6"><span className="tx-gray fw-bold">State</span> : </td>
                              </tr>
                              <tr>
                                <td><span className="tx-gray fw-bold">Date</span> : </td>
                                <td colSpan="6"><span className="tx-gray fw-bold">Place</span> :</td>
                              </tr>
                            </>
                          ))}
                          <tr>
                            <td>
                              {aufData.gs_signature !== null ? <img src={`${aufData.gs_signature}`} alt="Base64 Image" style={{ width: "235px", height: "100px" }} /> : ""}
                              <br />
                              <span className="tx-gray fw-bold"></span>1st Applicant Signature
                            </td>
                            <td><span className="tx-gray fw-bold">2nd Applicant Signature</span> </td>
                            <td colSpan="3"><span className="tx-gray fw-bold">3rd Applicant Signature</span></td>
                         </tr>
                        </tbody>
                      </table>
                      <div className="d-flex mt-3 mb-5" style={{ width: "100%" }}>
                        <button className="btn btn-primary btn-lg btn-full btn-md me-1" onClick={handleBackClick}> Edit</button>
                        <button className="btn btn-primary btn-lg btn-full btn-md ms-1"
                          onClick={(e) => {
                            e.preventDefault();
                            sendbseonboardotp(e);
                          }}
                        > Accept</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
              <div className="dflex align-items-center">
                <div>
                  <h6 className="mb-0">Review</h6>
                  <p className="tx-12 mb-0 line18">Review your details and confirm</p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="mobile-main mb-5 pb-5" style={{marginTop:"77px"}}>
        <h4 class="text-center mb-4">Verify Your Details</h4>

          <table className="clienttable" ref={contentRef}>
            <tbody >
              <tr>
                <td className="name">Broker/Agent Code ARN</td>
                <td>249633</td>
              </tr>
              <tr>
                <td className="name">Sub Broker</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">EUIN</td>
                <td>{aufData.gs_txn_euin}</td>
              </tr>
              <tr>
                <td colSpan="2"><strong>Unit Folder Information</strong></td>
              </tr>
              <tr>
                <td className="name">Name of the First Applicant</td>
                <td>{aufData.gs_fname} {aufData.gs_lname}</td>
              </tr>
              <tr>
                <td className="name">PAN Number</td>
                <td>{aufData.gs_pan_no}</td>
              </tr>
              <tr>
                <td className="name">KYC</td>
                <td>{registerData.bur_kyc_type}</td>
              </tr>
              <tr>
                <td className="name">Date of Birth</td>
                <td>{aufData.gs_dob}</td>
              </tr>
              <tr>
                <td className="name">Father Name</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Mother Name</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Name of Guardian</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">PAN</td>
                <td>-</td>
              </tr>
              <tr>
                <td colSpan="2"><strong>Contact Address</strong></td>
              </tr>
              <tr>
                <td className="name">City</td>
                <td>{aufData.gs_city}</td>
              </tr>
              <tr>
                <td className="name">Pincode</td>
                <td>{aufData.gs_pin}</td>
              </tr>
              <tr>
                <td className="name">State</td>
              <td>{aufData.gs_state}</td>
              </tr>
              <tr>
                <td className="name">Country</td>
                <td>{aufData.gs_country}</td>
              </tr>
              <tr>
                <td className="name">Tel(off)</td>
              <td>-</td>
              </tr>
              <tr>
                <td className="name">Tel(Res)</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Email</td>
                <td>{aufData.gs_email}</td>
              </tr>
              <tr>
                <td className="name">Fax(off)</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Fax(Res)</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Mobile</td>
                <td>{aufData.gs_mobile}</td>
              </tr>
              <tr>
                <td className="name">Income Tax Slab/NetWorth</td>
                <td>{registerData.bur_income_source}</td>
              </tr>
              <tr>
                <td className="name">Occupation Details</td>
                <td>{registerData.bur_occ_code}</td>
              </tr>
              <tr>
                <td className="name">Place of Birth</td>
                <td>{aufData.gs_palce_of_birth}</td>
              </tr>
              <tr>
                <td className="name">Country of Tax Residence</td>
                <td>{registerData?.bur_tax_resistance_country}</td>
              </tr>
              <tr>
                <td className="name" >Tax Id No</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Politically exposed person/Related to Politically exposed person etc.</td>
                <td className=""><td colSpan="6">{registerData?.bur_politically_expose == "Y" ? "Yes" : registerData?.bur_politically_expose == "N" ? "No" : "Relative"}</td></td>  
              </tr>
              <tr>
                <td className="name">Mode of Holding</td>
                <td>{registerData.bur_tax_status}</td>
              </tr>
              <tr>
                <td className="name">Occupation</td>
               <td>{registerData.bur_occ_code}</td>
              </tr>
              <tr>
                <td ><strong>Name of Second Applicant</strong></td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">PAN Number</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">KYC</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Date of Birth</td>
              <td>-</td>
              </tr>
              <tr>
                <td className="name">Place of Birth</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Country of Tax Residence</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Tax Id No</td>
               <td>-</td>
              </tr>
              <tr>
                <td className="name">Politically exposed person/Related to Politically exposed person etc.</td>
                <td className="">Yes/No</td>
              </tr>
              <tr>
                <td className="name">Tax Id No</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Politically exposed person/Related to Politically exposed person etc.</td>
                <td className="">Yes/No</td>
              </tr>
              <tr>
                <td className="name">Mode of Holding</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Occupation</td>
                <td>-</td>
             </tr>
              <tr>
                <td><strong>Name of Third Applicant</strong></td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">PAN Number</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">KYC</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Date of Birth</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Place of Birth</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Country of Tax Residence</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Tax Id No</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Politically exposed person/Related to Politically exposed person etc.</td>
                <td className="">Yes/No</td>
              </tr>
              <tr>
                <td className="name">Other Details of sole/1st Applicant</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Overseas Address(In case of NRI Investor)</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">City</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Pincode</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="name">Country</td>
                <td>-</td>
              </tr>
              {bankData.map((value) => (
                <>
                  <tr>
                    <th colSpan="2"><strong>Bank Mandate 1 Details:</strong></th>
                  </tr>
                  <tr>
                    <td className="name">Name of Bank</td>
                    <td>{value.bank.bse_bank_name}</td>
                  </tr>
                  <tr>
                    <td className="name">Branch</td>
                    <td>{value.ubd_bank_branch}</td>
                  </tr>
                  <tr>
                    <td className="name">A/C Number</td>
                    <td>{value.ubd_acc_no}</td>
                  </tr>
                  <tr>
                    <td className="name">A/C Type</td>
                    <td>{value.ubd_acc_type}</td>
                  </tr>
                  <tr>
                    <td className="name">IFSC Code</td>
                    <td>{value.ubd_ifsc}</td>
                  </tr>
                  <tr>
                    <td className="name">City</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td className="name">Pincode</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td className="name">State</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td className="name">Country</td>
                    <td>-</td>
                  </tr>
                </>))}
              <tr>
                <th colSpan="2"><strong>Nomination Details:</strong></th>
              </tr>
              {nomineeData.map((value) => (
                <>
                  <tr>
                    <td className="name">Nominee Name</td>
                    <td>{value.und_nominee_name}</td>
                  </tr>
                  <tr>
                    <td className="name">Relationship</td>
                    <td>{value.und_nominee_relationship_name}</td>
                  </tr>
                  <tr>
                    <td className="name">Guardian Name(If Nominee is Minor)</td>
                    <td>{value.und_nominee_guardian}</td>
                  </tr>
                  <tr>
                    <td className="name">A/C Type</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td className="name">IFSC Code</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <th colSpan="2"><strong>Nominee Address:</strong></th>
                  </tr>
                  <tr>
                    <td className="name">City</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td className="name">Pincode</td>
                    <td>-</td>
                 </tr>
                  <tr>
                    <td className="name">State</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td className="name">Date</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td className="name">Place</td>
                    <td>-</td>
                  </tr>
                </>
              ))}
              <tr>
                <td className="name">1st Applicant Signature</td>
                <td>  {aufData.gs_signature !== null ? <img src={`${aufData.gs_signature}`} alt="Base64 Image" style={{ width: "100%", height: "200px" }} /> : ''}<br></br></td>

              </tr>
              <tr>
                <td className="name">2nd Applicant Signature</td>
                <td>-</td>

              </tr>
              <tr>
                <td className="name">3rd Applicant Signature</td>
                <td>-</td>
              </tr>

            </tbody>

            <div className="footer-button">
              <div className='d-flex'>
                <button
                  className="btn btn-primary btn-lg btn-full btn-md me-1"
                  onClick={handleBackClick}
                >
                  Edit
                </button>
                <button
                  className="btn btn-primary btn-lg btn-full btn-md ms-1"
                  onClick={(e) => {
                    e.preventDefault();
                    sendbseonboardotp(e);

                  }}
                >
                  Accept
                </button>
              </div>
            </div>

          </table>
        </div>

      </MobileView>

      <Modal className="verifybse" show={showmodal} onHide={handlemodalclose}>
     
        <Modal.Body>
        <div className={submitLoader == true ? 'parentDisable' : ''} width="100%">
        <div className='overlay-box'>
          <Hourglass
            visible={submitLoader}
            ariaLabel="Loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={['#304AA7', '#304AA7']}
          />
          <p>{loaderName}</p>
        </div>
      </div>
          <div className="verifybse-content">
            <button className="pop-close" onClick={() => { handlemodalclose() }}><i class="d-icon-times"></i></button>
            <h5>Verify Your Details</h5>
            <p>OTP has been sent your register mobile number</p>
            <div className="from-group mt-2">
              <label>Verify OTP</label>
              <input
                type="number"
                name="mobile_otp"
                onChange={handleInput}
              ></input>
              <p className="mt-2 mb-0" style={{ fontSize: "12px" }}>We will send you OTP on your registered Mobile no.</p>
              {counter === 0 ? (
                <p className="tx-12 ">
                  Didn't receive OTP? Resend{" "}
                  <span
                    className="tx-theme"
                    style={{ cursor: "pointer" }}
                    onClick={resendOtpbr}
                  >
                    (Click Here)
                  </span>
                </p>
              ) : (
                <p className="tx-12 ">
                  Resend OTP in {counter} seconds
                </p>
              )}
              <button className="btn btn-primary btn-lg btn-full mt-1" onClick={() => { verifybseonboardotp() }}>Verify</button>
              {/* <button className="btn btn-primary btn-lg btn-full mt-1" onClick={uploadfile}>uploadfile</button> */}
            </div>
          </div>

        </Modal.Body>

      </Modal>
      <SweetAlert success confirmBtnCssClass='alertpop' confirmBtnText='Continue' title="UCC Registered Successfully" show={show} onConfirm={confirmContinue}>
      {UserUCC && UserUCC!='' ? <h5>UCC : {UserUCC}</h5> : ''}
      </SweetAlert>
    </>
  )
}
export default BseAufForm