import React, { useEffect, useRef, useState, useMemo } from 'react'
import { BrowserView, MobileView } from "react-device-detect";
import Header from '../../Components/Header';
import Skeleton from 'react-loading-skeleton';
import { Breadcrumb } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiService } from '../../Components/Services/apiServices';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import { TailSpin, InfinitySpin } from "react-loader-spinner";



const apiServices = new ApiService();
const PopuraBasketDetails = () => {

    const navigate = useNavigate();
    const didMountRef = useRef(true);
    const [popularBasketBasketData, setpopularBasketBasketData] = useState([]);
    const [amount, setAmount] = useState("")
    const [frequency, setFrequency] = useState("")
    const [otpScreen, setOtpScreen] = useState()
    const [counter, setCounter] = React.useState(0);
    const [countermob, setCountermob] = React.useState(0);
    const [seconds, setseconds] = useState(0);
    const [minutes, setminutes] = useState(10);
    const [warningShow, setWarningShow] = useState(false)
    const [otp, setOtp] = useState("")
    const [popularBasketName, setPopularBasketName] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [calenderDates, setCalenderDates] = useState([])
    const [minAmount, setMinAmount] = useState(0)
    const [bankData, setbankData] = useState([])
    const [bankype, setBankType] = useState("")
    const [selectBank, setSelectBank] = useState("")
    const [sipMonth, setSipMonth] = useState(240)
    const [matchedOTPMessage, setMatchedOTPMessage] = useState("")
    const { id } = useParams();
    const { goalID } = useParams();
    const numbers = [];
    const [amountValues, setAmountValues] = useState(0);
    const [sipDates, setSipDates] = useState(Array(popularBasketBasketData.length).fill(null));
    const [show, setShow] = useState(false)
    const [errorMessage, setErrorMesssage] = useState("")
    const [autoPayMessage, setAutoPayMessage] = useState("Please Set Autopay/Mandate For Your Primary Account")
    const [responseData, setResponseData] = useState([])
    const [submitLoader, setSubmitLoader] = useState(false)
    const [tempOtpId, setTempOtpId] = useState("")
   

    // const handleDateChange = (date, index) => {
    //     const newSipDates = [...sipDates];
    //     newSipDates[index] = date;
    //     setSipDates(newSipDates);
    // };
    for (let i = 12; i <= 240; i++) {
        numbers.push({ value: i, label: `${i} Month` });
    }

    const getbankdata = () => {

        apiServices.userBankDatapostrequest({}).then(res => {
            if (res.data.status == "success") {
                setbankData(res.data.userBankData)

            }

        })
    }
    useEffect(() => {
        if (didMountRef.current) {
            getbankdata()
            frequencyChange()


        }
        didMountRef.current = false;
    }, [])

    const frequencyChange = (value = "SIP") => {
        console.log("hello")
        setSubmitLoader(true)
        setFrequency(value)
        const dataString = {
            bmf_basket_id: id,
            frequency: value
        }
        apiServices.popularbasketdetailsurlpostrequest(dataString).then(res => {
            if (res.data.status === 'success') {
                setpopularBasketBasketData(res?.data?.popularbasketdetails)
                setPopularBasketName(res?.data?.popularbasket)
                setSubmitLoader(false)
                setMinAmount(res.data.minimumAmount)
                if (value == "SIP") {
                    res.data.popularbasketdetails.forEach(item => {


                        if (item.sip_detail !== null) {
                            setCalenderDates(item?.sip_detail?.mfsip_dates.split(','))
                        }
                    });
                }

            }
            else{
                setSubmitLoader(false)
            }
        })
    }

    const checkbankdirect = (event) => {
        const selectedValue = event
        const [bseBId, bseBankNodal] = selectedValue.split('_');
        setSelectBank(bseBId)
        setBankType(bseBankNodal)
    }

    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    React.useEffect(() => {
        countermob > 0 && setTimeout(() => setCountermob(countermob - 1), 1000);
    }, [countermob]);


    React.useEffect(() => {
        setTimeout(() => {
            if (seconds > 0) {
                setseconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                } else {
                    setseconds(59);
                    setminutes(minutes - 1);
                }
            }
        }, 1000);
    });
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const sendOtpMutualfund = async () => {
        console.log(amount)
        console.log(minAmount)
        if (amount == "") {
            toast.error("Please enter amount")
            return false
        }
        if (amount < minAmount) {
            toast.error(`Minimum amount should be more then ${minAmount}`)
            return false
        }
        if (selectBank == "") {
            toast.error("Please select bank")
            return false
        }
        for (let i = 0; i < popularBasketBasketData.length; i++) {
            if (!sipDates[i]) {
                // Show error message in toast
                await delay(1000); // Adjust delay as needed
                toast.error('SIP Date is required for ' + popularBasketBasketData[i].bmf_scheme_name);
                return false
            }
        }
        setIsLoading(true)
        const dataString = {
            type: "SIP"
        }
        apiServices.sendotptransactionPostRequest(dataString).then((result) => {
            if (result.data.status == "success") {
                setTempOtpId(result.data.temp_id)
                setIsLoading(false)
                // setLoaderName("Send OTP")
                setCounter(30);
                setCountermob(30)
                setOtpScreen(true)
            }
            else {
                setIsLoading(false)
                toast.error(result.data.message)
            }
        })
    }
    const monthAvailableDates = calenderDates;
    const generateEnabledDates = () => {
        const dates = [];

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        // Dates for the current month
        monthAvailableDates.forEach(day => {
            const date = new Date(currentYear, currentMonth, day);
            dates.push(date);
        });

        // Dates for the next month
        const nextMonth = (currentMonth + 1) % 12; // Using modulo to loop back to January if December
        const nextYear = (currentMonth === 11) ? currentYear + 1 : currentYear; // Increment year if December

        monthAvailableDates.forEach(day => {
            const date = new Date(nextYear, nextMonth, day);
            dates.push(date);
        });

        return dates;
    };

    const handleDateChange = (date, index, id) => {
        const Mydate = moment(date).format('DD/MM/YYYY')
        const newSipDates = [...sipDates];
        newSipDates[index] = date;
        setSipDates(newSipDates);
        console.log(popularBasketBasketData)

        const results = popularBasketBasketData.map(item => {
            // Check if the item's 'bmf_id' matches the 'id'
            if (item.bmf_id === id) {
                // If the condition is true, update the 'sipdates' parameter and return the updated item
                return { ...item, sipdates: Mydate };
            } else {
                // If the condition is false, return the item as is
                return item;
            }
        });
        setpopularBasketBasketData(results);
    };
    const resendOtpbr = () => {
        setIsLoading(true)
        apiServices.sendotptransactionPostRequest().then(res => {
            if (res.data.status == 'success') {
                setTempOtpId(res.data.temp_id)
                setCounter(30);
                setminutes(10);
                setseconds(0);
                setIsLoading(false)
                return false;
            }
            else {
                toast(res.data.message);
                return false;
            }
        })
    }

    const handlesipmonth = (value) => {
        setSipMonth(value.value)
    }
    const verifyOTP = () => {
        if (otp == "") {
            toast.error("OTP is required")
            return false
        }
        setIsLoading(true)
        const dataString = {
            otp: otp,
            temp_otp_id: tempOtpId

        }
        apiServices.verifyotptransactionPostRequest(dataString).then((result) => {
            if (result.data.status == "success") {

                const dataString1 = {
                    multi_fund_details: popularBasketBasketData,
                    bank_id: selectBank,
                    frequencyType: 'MONTHLY',
                    SipYear: sipMonth,
                    goal_id: goalID
                }
                console.log(dataString1)

                apiServices.createbseXSIPorderpopularbasketpostrequest(dataString1).then((result) => {
                    console.log(result, "apixs")
                    if (result.data.status == "success") {
                        setIsLoading(false)

                        const dataString = {
                            transId: result.data.transactionArray
                        }
                        apiServices.getmultipletransactiondetailpostrequest(dataString).then(res => {
                            if (res.data.status == "success") {
                                setResponseData(res.data.data)
                                setShow(true)
                            }

                        })



                    }
                    else if (result.data.status == "error") {
                        setIsLoading(false)
                        setWarningShow(true)
                        setMatchedOTPMessage(result.data.message)
                    }

                })
            }
            else if (result.data.status == "error") {
                toast.error(result?.data?.message)
                setIsLoading(false)

            }
        })
    }
    const amountChange = (value) => {
        const newValue = value.replace(/\D/g, '');

        setAmount(newValue)
        if(minAmount > newValue){
            document.getElementById('myInput').style.border = '2px solid red';
            setAmountValues(1)
            return
        }
        else{
            setAmountValues(0)
            document.getElementById('myInput').style.border = ''
            
        }
        if (newValue % 500 !== 0) {
            setErrorMesssage("Amount should be multiple of 500")
            setAmountValues(1)
            return false
        }
        else {
            setAmountValues(0)
            setErrorMesssage("")
        }

      
    

        if (newValue >= minAmount) {
            console.log("hello3")
            const dataString = {
                "basket_total_amount": newValue,
                "bmf_basket_id": popularBasketName.pbgs_id,

            }
            apiServices.getdistributedpopularbasketpostrequest(dataString).then(res => {
                if (res.data.status == "success") {
                    setpopularBasketBasketData(res.data.popularbasketdetails)
                   
                }
                else {
                    toast.error(res.data.message)
                }
            })
        }
        else {
          
        }
    }

    const enabledDates = generateEnabledDates();
    const handlewarningOkClick = () => {
        setWarningShow(false);
        console.log(matchedOTPMessage)

        if (matchedOTPMessage == autoPayMessage) {
            navigate("/bank")

        } else {
            window.location.reload()
        }
    };

    const handleOkClick = () => {
        window.location.reload()



    };

    const getTomorrowDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        return tomorrow;
      };
    return (
        <>
         {submitLoader ? <>
          <div className={submitLoader == true ? 'parentDisable' : ''} width="100%">
            <div className='overlay-box'>

            <TailSpin
              color="#ffffff"
              visible={submitLoader}
            />
       
            </div>
          </div>
        </> : ""}
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
            <BrowserView>
                <Header />

                <div className="subheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1>Investment Basket</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/mutual-funds">Popular Basket</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{popularBasketName.pbgs_basket_name ? popularBasketName.pbgs_basket_name : <Skeleton width="200px" height="10px" />}</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='sec-pad-sm'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-7'>
                                <div className='panel mb-15'>
                                    <div className='panel-header'>
                                        <h6 className='mb-0'>{popularBasketName.pbgs_basket_name}</h6>
                                    </div>
                                    <div className='panel-body'>
                                        <div className='row g-3'>
                                            <div className='col-lg-12'>

                                                <div className='from-group mb-1'>
                                                    <label>Amount</label>
                                                    <input type="text" id='myInput' className='form-control w-100 required mb-2' onInput={(e) => { amountChange(e.target.value) }} value={amount} />
                                                    {errorMessage == null || errorMessage == "" ? <p className='tx-12 tx-gray mb-0'>Minimum Amount: {minAmount}</p> : ''}
                                                    {errorMessage !== null || errorMessage == "" ? <p className='text-danger tx-12 mb-0'>{errorMessage}</p> : ""}
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='from-group mb-1'>
                                                    <label>Frequency</label>
                                                    <select className='custom-select' onChange={handlesipmonth}>
                                                        <option value="">Select Month</option>
                                                        {numbers.map((value) => (
                                                            <option value={`${value.value}_${value.label}`} selected={240}>{value.label}</option>
                                                        ))}
                                                    </select>

                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='from-group mb-1'>
                                                    <label>Bank</label>
                                                    <select className='custom-select' onChange={(e) => checkbankdirect(e.target.value)}>
                                                        <option value="">Select Bank</option>
                                                        {bankData.map((value) => (
                                                            <option value={`${value.ubd_id}_${value.bank.bse_bank_nodal}`} >{value.bank.bse_bank_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                   
                                    popularBasketBasketData.map((value, index) => {
                                        let amount = amountValues == 1 ?0:value.investmentamt;
                                        return(
                                        <div className='panel mb-15'>
                                            <div className='panel-header'>
                                                <h6 className='mb-0'>{value.bmf_scheme_name}</h6>
                                            </div>
                                            <div className='panel-body'>
                                                <div className='row g-3'>
                                                    <div className='col-lg-6'>
                                                    <div className='from-group'>
                                                        <label>Amount</label>
                                                        <input type="number" name="" className='form-control w-100 required' disabled value={amount} />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                    <div className='from-group'>
                                                        <label>SIP Date</label>
                                                        <DatePicker
                                                        style={{ width: "400px" }}
                                                        className="custom-select"
                                                        dateFormat="dd/MM/yyyy"

                                                        includeDates={enabledDates}
                                                        minDate={getTomorrowDate()}
                                                        onChange={(date) => handleDateChange(date, index, value.bmf_id)}
                                                        //    onClick={handleDateChange}
                                                        selected={sipDates[index]}
                                                    />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                     })
                                  

                                }
                                <div className='panel text-center'>
                                    <div className='panel-body'>
                                        <h5>Invested Amount : <span className='fw700'>{amount}</span></h5>
                                        <div className='from-group mb-1'>
                                        {otpScreen == true ? <>
                                            <label htmlFor="">Verify OTP</label>
                                            <input type="number" name="verifyotp" className='form-control w-100 required' id="" onChange={(e) => { setOtp(e.target.value) }} />
                                            {counter === 0 ? (
                                                <p className="tx-12 mt-2">
                                                    Didn't receive OTP? Resend{" "}
                                                    <span
                                                        className="tx-theme"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={resendOtpbr}
                                                    >
                                                        (Click Here)
                                                    </span>
                                                </p>
                                            ) : (
                                                <p className="tx-12 mt-2 mb-0">
                                                    Resend OTP in {counter} seconds
                                                </p>
                                            )}
                                        </>
                                            : ""}
                                            {otpScreen == true ?
                                        <button type="button" className="btn btn-primary btn-lg btn-full mt-10" onClick={verifyOTP} disabled={isLoading}>  {isLoading ? (
                                            <img src="/img/loder01.gif" width="60px" height="11px" />
                                        ) : (
                                            "Verify OTP"
                                        )}</button>
                                        : <button type="button" className="btn btn-primary btn-lg btn-full mt-10" onClick={sendOtpMutualfund} disabled={isLoading}> {isLoading ? (
                                            <img src="/img/loder01.gif" width="60px" height="11px" />
                                        ) : (
                                            "Send OTP"
                                        )}</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


            </BrowserView>
            <MobileView>
              
                <header className="mheader">
                    <div className="container">
                        <div className="mheader-left">
                            <div className="dflex align-items-center" >
                                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/popular-basket") }}></i>
                                <div className="ms-3">
                                    <h6 className="mb-0">{popularBasketName.pbgs_basket_name ? popularBasketName.pbgs_basket_name : <Skeleton width="200px" height="10px" />}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <section className='sec-pad-lg'>
                <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-7'>
                                <div className='panel mb-15'>
                                    <div className='panel-header'>
                                        <h6 className='mb-0'>{popularBasketName.pbgs_basket_name}</h6>
                                    </div>
                                    <div className='panel-body'>
                                        <div className='row g-3'>
                                            <div className='col-lg-12'>

                                                <div className='from-group mb-1'>
                                                    <label>Amount</label>
                                                    <input type="text" id='myInput' className='form-control w-100 required mb-2' onInput={(e) => { amountChange(e.target.value) }} value={amount} />
                                                    {errorMessage == null || errorMessage == "" ? <p className='tx-12 tx-gray mb-0'>Minimum Amount: {minAmount}</p> : ''}
                                                    {errorMessage !== null || errorMessage == "" ? <p className='text-danger tx-12 mb-0'>{errorMessage}</p> : ""}
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='from-group mb-1'>
                                                    <label>Frequency</label>
                                                    <select className='custom-select' onChange={handlesipmonth}>
                                                        <option value="">Select Month</option>
                                                        {numbers.map((value) => (
                                                            <option value={`${value.value}_${value.label}`} selected={240}>{value.label}</option>
                                                        ))}
                                                    </select>

                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='from-group mb-1'>
                                                    <label>Bank</label>
                                                    <select className='custom-select' onChange={(e) => checkbankdirect(e.target.value)}>
                                                        <option value="">Select Bank</option>
                                                        {bankData.map((value) => (
                                                            <option value={`${value.ubd_id}_${value.bank.bse_bank_nodal}`} >{value.bank.bse_bank_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                   
                                    popularBasketBasketData.map((value, index) => {
                                        let amount = amountValues == 1 ?0:value.investmentamt;
                                        return(
                                        <div className='panel mb-15'>
                                            <div className='panel-header'>
                                                <h6 className='mb-0'>{value.bmf_scheme_name}</h6>
                                            </div>
                                            <div className='panel-body'>
                                                <div className='row g-3'>
                                                    <div className='col-lg-6'>
                                                    <div className='from-group'>
                                                        <label>Amount</label>
                                                        <input type="number" name="" className='form-control w-100 required' disabled value={amount} />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                    <div className='from-group'>
                                                        <label>SIP Date</label>
                                                        <DatePicker
                                                        style={{ width: "400px" }}
                                                        className="custom-select"
                                                        dateFormat="dd/MM/yyyy"

                                                        includeDates={enabledDates}
                                                        minDate={getTomorrowDate()}
                                                        onChange={(date) => handleDateChange(date, index, value.bmf_id)}
                                                        //    onClick={handleDateChange}
                                                        selected={sipDates[index]}
                                                    />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                     })
                                  

                                }
                                <div className='panel text-center'>
                                    <div className='panel-body'>
                                        <h5>Invested Amount : <span className='fw700'>{amount}</span></h5>
                                        <div className='from-group mb-1'>
                                        {otpScreen == true ? <>
                                            <label htmlFor="">Verify OTP</label>
                                            <input type="number" name="verifyotp" className='form-control w-100 required' id="" onChange={(e) => { setOtp(e.target.value) }} />
                                            {counter === 0 ? (
                                                <p className="tx-12 mt-2">
                                                    Didn't receive OTP? Resend{" "}
                                                    <span
                                                        className="tx-theme"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={resendOtpbr}
                                                    >
                                                        (Click Here)
                                                    </span>
                                                </p>
                                            ) : (
                                                <p className="tx-12 mt-2 mb-0">
                                                    Resend OTP in {counter} seconds
                                                </p>
                                            )}
                                        </>
                                            : ""}
                                            {otpScreen == true ?
                                        <button type="button" className="btn btn-primary btn-lg btn-full mt-10" onClick={verifyOTP} disabled={isLoading}>  {isLoading ? (
                                            <img src="/img/loder01.gif" width="60px" height="11px" />
                                        ) : (
                                            "Verify OTP"
                                        )}</button>
                                        : <button type="button" className="btn btn-primary btn-lg btn-full mt-10" onClick={sendOtpMutualfund} disabled={isLoading}> {isLoading ? (
                                            <img src="/img/loder01.gif" width="60px" height="11px" />
                                        ) : (
                                            "Send OTP"
                                        )}</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </MobileView>

            <SweetAlert confirmBtnCssClass='alertpop' confirmBtnText='Coutinue' title={matchedOTPMessage} show={show} onConfirm={handleOkClick}>

                <>
                    <table className="clienttable">
                        <tr>
                            <th>Scheme Name</th>
                            <th>Status</th>
                            <th>Message</th>
                        </tr>
                        {responseData.map((value) => (
                            <tr>
                                <td>{value.trans_mf_name}</td>
                                <td><span className={`badge ${value?.trans_status == 1 ? "bg-warning-subtle text-warning" : value?.trans_status == 0 ? "bg-primary-subtle text-primary" : value?.trans_status == 2 ? "bg-danger-subtle text-danger" : ""} `}>{value?.trans_status == 1 ? "in process" : value?.trans_status == 0 ? "Pending" : value?.trans_status == 2 ? "Transaction Failed" : value?.trans_status == 3 ? "Successful" : ""}</span></td>
                                <td>{value.trans_status_text}</td>
                            </tr>))}
                    </table>
                </>

            </SweetAlert>
            <SweetAlert danger confirmBtnCssClass='alertpop' confirmBtnText='Ok' title={matchedOTPMessage} show={warningShow} onConfirm={handlewarningOkClick}>
            </SweetAlert>
        </>
    )
}

export default PopuraBasketDetails